$emailVerificationModal = $("#email-verification-modal");
$emailVerificationForm = $("#email-verification-form");
$emailVerificationResendBtn = $("#email-verification-resend");
$(function(){
    $('#email-verification-resend').hide();
    validateEmailVerificationForm();
    emailVerificationFormListeners();
    /*var timeleft = 180;
    var downloadTimer = setInterval(function(){
      if(timeleft <= 0){
        clearInterval(downloadTimer);
        $('#email-verification-resend').show();
        $('#otp-seconds').hide();
        $('.otp-error').hide();
      } else {
        document.getElementById("otp-seconds").innerHTML = timeleft;
        $('#otp-seconds').show();
        $('.otp-error').show();
        $('#email-verification-resend').hide();
      }
      timeleft -= 1;
    }, 1000);*/

})
function validateEmailVerificationForm()
{
    $emailVerificationForm.validate({
        rules: {
            email_or_number: {
                required:true,
            },
            otp: {
                required: true,
                minlength: 6,
                maxlength: 6
            },
        },
        messages: {
            otp: {
                required: "Please enter your OTP",
                minlength: "The OTP must be of 6 digits",
                maxlength: "OTP should not be more than 6 digits"
            }
        }
    });
}
function emailVerificationFormListeners()
{
    $emailVerificationResendBtn.on('click', function(){
        var email = $emailVerificationForm.find('[name=email_or_number]').val();
        resendEmailVerification(email);
    })
    $emailVerificationForm.ajaxForm({
        url:appConfig.api_url+"signin",
        dataType: 'json',
        beforeSend:function(){
          showLoader(); 
        },
        success:function(response){
            hideLoader();
            Cookies.set('access_token', response.data.accessToken);
            Cookies.set('uuid', response.data.userDetail.uuid);
            Swal.fire({
                icon: response.meta.status?"success":"error",
                text: response.meta.message,
            }).then(function(){
                $emailVerificationModal.modal('hide');
                window.location.href= response.data.redirect_url;
            });
        },
        error: function (xhr) {
            hideLoader();
            showErrorAlertFromException(xhr)
        }
    });
}

function resendEmailVerification(email)
{
    $.ajax({
        url:appConfig.api_url+"resend-otp",
        dataType: 'json',
        type:"POST",
        data: {
            email:email,
            signin: true
        },
        beforeSend:function(){
          showLoader();
        },
        success:function(response){
            hideLoader();
            Swal.fire({
                icon: response.meta.status?"success":"error",
                text: response.meta.message,
            }).then(function(){
                $emailVerificationForm[0].reset();
            })
            var timeleft = 180;
            var downloadTimer = setInterval(function(){
              if(timeleft <= 0){
                clearInterval(downloadTimer);
                $('#email-verification-resend').show();
                $('#otp-seconds').hide();
                $('.otp-error').hide();
              } else {
                document.getElementById("otp-seconds").innerHTML = timeleft;
                $('#otp-seconds').show();
                $('.otp-error').show();
                $('#email-verification-resend').hide();
              }
              timeleft -= 1;
            }, 1000);
        },
        error: function (xhr, ) {
            hideLoader();
            var response = JSON.parse(xhr.responseText);
            Swal.fire({
                icon:"error",
                text: response.meta.message
            })
        }
    })
}
window.showEmailVerificationModal = function(email)
{
    $emailVerificationForm.find("[name=email_or_number]").val(email);
    $emailVerificationForm[0].reset();
    $emailVerificationModal.modal({backdrop: 'static', keyboard: false})
    $('.veriyfy_email').html('<b>'+email+'</b>');  
    $emailVerificationModal.modal('show');
    var timeleft = 180;
    var downloadTimer = setInterval(function(){
      if(timeleft <= 0){
        clearInterval(downloadTimer);
        $('#email-verification-resend').show();
        $('#otp-seconds').hide();
        $('.otp-error').hide();
      } else {
        document.getElementById("otp-seconds").innerHTML = timeleft;
        $('#otp-seconds').show();
        $('.otp-error').show();
        $('#email-verification-resend').hide();
      }
      timeleft -= 1;
    }, 1000);
}

$(".btn-close").on('click', function(){
    location.reload();
})